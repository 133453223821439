#root {
  background: #F3F3F3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes fromLeft {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fromRight {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
