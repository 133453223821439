@font-face {
  font-family: 'Ubuntu';
  src: url('./Medium/Ubuntu-Medium.eot');
  src: url('./Medium/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Medium/Ubuntu-Medium.woff2') format('woff2'),
    url('./Medium/Ubuntu-Medium.ttf') format('truetype'),
    url('./Medium/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* latin regular */
@font-face {
  font-family: 'Ubuntu';
  src: url('./Regular/Ubuntu-Regular.eot');
  src: url('./Regular/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Regular/Ubuntu-Regular.woff2') format('woff2'),
    url('./Regular/Ubuntu-Regular-latin.woff') format('woff'),
    url('./Regular/Ubuntu-Regular.ttf') format('truetype'),
    url('./Regular/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic regular */
@font-face {
  font-family: 'Ubuntu';
  src: url('./Regular/Ubuntu-Regular.eot');
  src: url('./Regular/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Regular/Ubuntu-Regular.woff2') format('woff2'),
    url('./Regular/Ubuntu-Regular-cyrillic.woff') format('woff'),
    url('./Regular/Ubuntu-Regular.ttf') format('truetype'),
    url('./Regular/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic regular */
@font-face {
  font-family: 'Ubuntu';
  src: url('./Regular/Ubuntu-Regular-kyrgyz.eot');
  src: url('./Regular/Ubuntu-Regular-kyrgyz.eot') format('embedded-opentype'),
    url('./Regular/Ubuntu-Regular-kyrgyz.woff2') format('woff2'),
    url('./Regular/Ubuntu-Regular-kyrgyz.woff') format('woff'),
    url('./Regular/Ubuntu-Regular-kyrgyz.ttf') format('truetype'),
    url('./Regular/Ubuntu-Regular-kyrgyz.svg#Ubuntu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Medium/Ubuntu-Medium-kyrgyz.eot');
  src: url('./Medium/Ubuntu-Medium-kyrgyz.eot?#iefix') format('embedded-opentype'),
    url('./Medium/Ubuntu-Medium-kyrgyz.woff2') format('woff2'),
    url('./Medium/Ubuntu-Medium-kyrgyz.ttf') format('truetype'),
    url('./Medium/Ubuntu-Medium-kyrgyz.svg#Ubuntu-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}